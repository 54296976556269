import React from 'react';
import Container from '../../container/container';
import { InternalLinkButton } from '../../button/internal-link-button';
import styles from './cta-dark-section.module.scss';

const CtaDarkSection = ({ text, cta: { to, label }, ...otherProps }) => {
  return (
    <Container {...otherProps}>
      <div className={styles.cta}>
        <p>{text}</p>
        <InternalLinkButton to={to} className={styles.button}>
          {label}
        </InternalLinkButton>
      </div>
    </Container>
  );
};

export default CtaDarkSection;
