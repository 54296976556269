import React from 'react';
import { graphql } from 'gatsby';
import LayoutContent from '../components/layout/layout-content';
import Container from '../components/container/container';
import SEO from '../components/seo';
import { WebsitePreview } from '../components/website-preview/website-preview';
import { SubHeading } from '../components/headings/sub-heading';
import BaseBlockContent from '@sanity/block-content-to-react';
import { BlockContent } from '../components/block-content/block-content';
import DownArrow from '../images/icons/arrow_down_icon.svg';
import { useMedia } from '../hooks/useMedia';
import { TestimonialLink } from '../components/testimonial-link/testimonial-link';
import CtaDarkSection from '../components/sections/cta-dark-section/cta-dark-section';
import AssetImg from '../components/asset/asset-img';
import styles from './testimonial-detail.module.scss';

const TestimonialDetail = (props) => {
  const { data } = props;
  const { currentTestimonial, previousTestimonial, nextTestimonial } = data;
  const {
    personName,
    website,
    description,
    mainImage,
    testimonial,
    personImage,
    personRole,
    client,
    services,
    servicesIcons,
    slideshows,
  } = currentTestimonial;

  const shouldShowSlider = useMedia([`(min-width: 768px)`], [true], false);

  return (
    <LayoutContent>
      <SEO title="Referenzen - Evelan GmbH" bodyClass={styles.body} />
      <div className={styles.background} />
      <div className={styles.backgroundContainer}>
        {/* Hero  */}
        <div className={styles.testimonialHero}>
          <div className={styles.background} />
          <Container className={styles.heroContainer}>
            <div className={styles.textContainer}>
              <h2>{personName}</h2>
              <h6>{website.split('//')[1]}</h6>
              <BaseBlockContent blocks={description} />
              <div className={styles.showServices}>
                <img src={DownArrow} alt="Pfeil nach unten" />
                <h4>
                  Folgende Leistungen wurden <br /> umgesetzt
                </h4>
              </div>
            </div>
            <WebsitePreview fluidAsset={mainImage.asset.localFile.childImageSharp.fluid} activateOnHover />
          </Container>
        </div>

        {/* Services */}
        {services && (
          <Container className={styles.servicesContainer}>
            {services.map((service) => {
              const { _key, body } = service;

              const localIcon = servicesIcons.find((serviceIcon) => {
                const localFileId = serviceIcon.icon.asset._id;
                return localFileId === service.icon.asset._id;
              });

              return (
                <div key={_key} className={styles.serviceItem}>
                  <div className={styles.serviceIcon}>
                    <AssetImg asset={localIcon.icon.asset} />
                  </div>
                  <div className={styles.serviceBody}>
                    <BlockContent blocks={body} />
                  </div>
                </div>
              );
            })}
          </Container>
        )}

        {/* Testimonial */}
        {testimonial && (
          <Container className={styles.testimonialContainer}>
            <SubHeading>Kundenstimme</SubHeading>
            <div className={styles.testimonialWrapper}>
              <div className={styles.testimonialQuote}>
                <span className={styles.quoteIcon}>“</span>
                <BaseBlockContent blocks={testimonial} />
              </div>
              <div className={styles.testimonialOwner}>
                <div className={styles.title}>
                  <h5>{personName}</h5>
                  <h6>
                    {personRole}
                    {client && `, ${client.name}`}
                  </h6>
                </div>
                {personImage && (
                  <div className={styles.picture}>
                    <AssetImg asset={personImage.asset} alt={client.name} />
                  </div>
                )}
              </div>
            </div>
          </Container>
        )}

        {/* Sliders */}
        {slideshows && shouldShowSlider && <BlockContent blocks={slideshows} />}

        {slideshows && !shouldShowSlider && (
          <Container>
            {slideshows.map((slideshow) => {
              const { title, slides, _key } = slideshow;
              return (
                <div className={styles.slideContainer} key={_key}>
                  <SubHeading>{title}</SubHeading>
                  {slides.map((slide) => {
                    const { caption, slideImage, _key } = slide;
                    return (
                      <div key={_key}>
                        <AssetImg asset={slideImage.asset} alt={caption} />
                        <p className={styles.caption}>{caption}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </Container>
        )}

        <CtaDarkSection
          className={styles.cta}
          text="Stellen Sie jetzt Ihre kostenfreie Projektanfrage!"
          cta={{
            label: 'Jetzt Anfragen',
            to: '/projektanfrage/#inquiry-header',
          }}
        />

        <Container className={styles.otherTestimonials}>
          <SubHeading>Weitere Referenzen</SubHeading>
          <div>
            {previousTestimonial && <TestimonialLink testimonial={previousTestimonial} variant="compact" />}
            {nextTestimonial && <TestimonialLink testimonial={nextTestimonial} variant="compact" />}
          </div>
        </Container>
      </div>
    </LayoutContent>
  );
};

export default TestimonialDetail;

export const query = graphql`
  fragment BasicInformation on SanityTestimonial {
    _id
    description: _rawDescription
    personName
    website
    slug {
      current
    }
    mainImage {
      asset {
        _id
        localFile {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 500
              traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#ffffff", color: "#828282" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
  query TestimonialById($id: String!, $previousId: String, $nextId: String) {
    previousTestimonial: sanityTestimonial(_id: { eq: $previousId }) {
      ...BasicInformation
    }
    nextTestimonial: sanityTestimonial(_id: { eq: $nextId }) {
      ...BasicInformation
    }
    currentTestimonial: sanityTestimonial(_id: { eq: $id }) {
      ...BasicInformation
      testimonial: _rawTestimonial
      services: _rawServices(resolveReferences: { maxDepth: 5 })
      servicesIcons: services {
        icon {
          asset {
            _id
            extension
            localFile {
              publicURL
              childImageSharp {
                fixed(
                  height: 80
                  width: 80
                  traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#ffffff", color: "#828282" }
                ) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
      slideshows: Slideshows {
        _key
        _type
        title
        slides {
          _key
          caption
          slideImage {
            asset {
              _id
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1080
                    traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#ffffff", color: "#828282" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      personRole
      client {
        name
      }
      personImage {
        asset {
          localFile {
            childImageSharp {
              fixed(
                height: 80
                width: 80
                traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#ffffff", color: "#828282" }
              ) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
