import React, { useContext } from 'react';
import Img from 'gatsby-image';
import AssetContext from '../../contexts/AssetContext';

/**
 * Represents a proper image component based on the data provided by <code>asset</code> parameter
 *
 * @param asset Asset object of GraphQL result that represetns Sanity.io asses data structure
 * @param alt alt text of the image tag
 * @param nonStretch in case of fluid image avoiding stretched images using the fluid type.
 * @param other
 * @returns {null|*}
 * @constructor
 */
export function AssetImg({ asset, alt, nonStretch = false, ...other }) {
  const { assetContext } = useContext(AssetContext);
  if (!asset) {
    return null;
  }

  const ariaProps = isBlank(alt)
    ? {
        alt: 'presentation image',
        role: 'presentation',
      }
    : {
        alt,
        role: undefined,
      };

  // check if the asses is correct fluid asset (and not just a reference)
  if (isFluidAsset(asset)) {
    return (
      <Img
        {...other}
        fluid={asset.localFile.childImageSharp.fluid}
        alt={alt}
        {...getNonStrechedStyleProp(asset, nonStretch, other)}
      />
    );
  }

  if (isFixedAsset(asset)) {
    return <Img fixed={asset.localFile.childImageSharp.fixed} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  }

  // Check if the image if a SVG file with valid publicURL
  if (asset.extension && asset.extension === 'svg' && asset.localFile && asset.localFile.publicURL) {
    return <img src={asset.localFile.publicURL} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  }
  if (asset.extension && asset.extension === 'svg' && asset.publicURL) {
    return <img src={asset.publicURL} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  }

  // --------------------------------------
  // From here it could be only a reference from the AssetContext
  // --------------------------------------

  // check if it's a reference
  const assetId = asset._ref || asset._id;
  if (!assetId) {
    console.error('Asset ID not found on asset: ', asset);
    return null;
  }

  // search for the image of the AssetContext (please see the GrapphQL query in the content-page.js to understand where it's came from)
  const localFile = findImageAssetByRef(assetContext.assets, asset._ref || asset._id);
  if (!localFile) {
    console.error(
      `Asset with ID: "${assetId}" was not found in the asset content used on this page. Please check your GraphQL query! Current asset context: `,
      assetContext.assets
    );
    return null;
  }

  if (asset.extension && asset.extension === 'svg' && localFile.publicURL) {
    return <img src={localFile.publicURL} {...other} alt={ariaProps.alt} role={ariaProps.role} />;
  } else if (asset.extension !== 'svg' && localFile.childImageSharp && localFile.childImageSharp.fluid) {
    return (
      <Img
        fluid={localFile.childImageSharp.fluid}
        {...other}
        alt={ariaProps.alt}
        role={ariaProps.role}
        {...getNonStrechedStyleProp(asset, nonStretch, other)}
      />
    );
  }

  return null;
}

/**
 * Avoiding stretched images using the fluid type.
 *
 * As mentioned previously, images using the fluid type are stretched to match the container’s width. In the case
 * where the image’s width is smaller than the available viewport, the image will stretch to match the container,
 * potentially leading to unwanted problems and worsened image quality.
 *
 * see more details here: https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
 *
 *
 * @param asset asset with presentationWidth field
 * @param nonStretch deactivate stretching or not
 * @param props props of AssetImg
 * @returns {{style: {}}|{style: {margin: string, maxWidth: ({type: any}|{type: *}|{type: *})}}}
 */
function getNonStrechedStyleProp(asset, nonStretch, props) {
  if (nonStretch && asset.localFile.childImageSharp.fluid.presentationWidth) {
    return {
      style: {
        ...(props.style || {}),
        maxWidth: asset.localFile.childImageSharp.fluid.presentationWidth,
        margin: '0 auto', // Used to center the image
      },
    };
  }

  return {
    style: { ...(props.style || {}) },
  };
}

/**
 * Added Asset Image helpers here since they will not be used anywhere else.
 */
function isFluidAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fluid;
}

function isFixedAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fixed;
}

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

function findImageAssetByRef(images, id) {
  if (!id || !images || !images.nodes) {
    return undefined;
  }

  const imageAsset = images.nodes.find((imgNode) => imgNode.id === id);
  if (!imageAsset) {
    return undefined;
  }

  return imageAsset.localFile && imageAsset.localFile;
}

export default AssetImg;
